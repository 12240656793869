.main-layout {
  .h-100 {
    height: 100%;
  }
  .layoutSidenav_content {
    background-color: #ebf4f8;
    border-radius: 25px;
    padding: 30px;
    height: 100%;
  }
}

@media (min-width: 990px) and (max-width: 1920px) {
  .main-layout {
    background-color: #ebf4f8;
    .sb-sidenav {
      padding-top: 0px !important;
    }
    #layoutSidenav_content {
      padding: 17px 23px !important;
      border-radius: 15px;
    }
    &.sb-nav-fixed {
      #layoutSidenav {
        #layoutSidenav_content {
          margin-left: 225px !important;
          padding-left: 0px;
          top: 0px;
        }
      }
    }
    .layoutSidenav_content {
      background-color: #f8f8f8;
      border-radius: 25px;
      padding: 30px;
      height: 100%;
      overflow-y: scroll;
      height: 95vh;
    }
  }
}
@media (min-width: 990px) and (max-width: 1920px) {
  .main-layout {
    .sb-sidenav-light {
      background: none;
    }
    .sb-topnav {
      position: static;
      display: none;
    }
  }
}

// .layoutSidenav_content::-webkit-scrollbar {
//   /*    width: 7px;
//     border-radius: 15px;*/
//   display: none;
// }

// /* Track */

// .layoutSidenav_content::-webkit-scrollbar-track {
//   /*background: #f1f1f1;*/
//   display: none;
// }

// /* Handle */

// .layoutSidenav_content::-webkit-scrollbar-thumb {
//   /* background: #DBE5ED;*/
//   display: none;
// }

// /* Handle on hover */

// ::-webkit-scrollbar-thumb:hover {
//   /*    background: #555;
//     border-radius: 15px;*/
//   display: none;
// }
