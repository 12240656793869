.inner-box-header {
  padding: 10px 0px;
  position: relative;
  margin-bottom: 20px;

  &.Hright-img {
    &:after {
      content: url("../../img/inner-hImg.svg");
      position: absolute;
      right: 0px;
      top: 27px;
    }
  }
  &.Order-Review {
    &:after {
      content: url(../../img/img-big.png) !important;
      position: absolute;
      right: 0px;
      top: 65px;
    }
  }

  h3 {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    padding: 4px 0px;
  }
}

.section-header {
  margin: 8px 0px;
  display: inline-block;
  width: 100%;
  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #1a3449;
  }
  .btn-group {
    &.btn-group-float-r {
      float: right;
    }
  }
}

.section-header {
  .head-notify {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      content: "\f111";
      font-family: "FontAwesome";
      width: 5px;
      height: 5px;
      color: #ff0000;
      border-radius: 50px;
      z-index: 1;
      position: absolute;
      top: 9px;
      right: 15px;
      display: block;
      font-size: 11px;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      list-style: none;
      display: inline-block;
      margin: 2px 10px;
    }
  }
  .Divright {
    float: right;
  }
  .user-holder {
    align-items: center;
    .user-cnt {
      h2 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        margin: 0px;
      }
      p {
        font-size: 13px;
        line-height: 22px;
        font-weight: 400;
        margin: 0px;
      }
    }
  }
}
