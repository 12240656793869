.ctgry-box {
  cursor: pointer;
  border: 3px solid transparent;

  &:hover {
    text-decoration: none;
    box-shadow: #dfdfdf -1px 1px 10px;
    border: 3px solid #6566b2;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .ctgry-icon {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    &:before {
      margin-right: 10px;
    }

    // &.icon-1 {
    //   &:before {
    //     content: url(../../img/busowner.png);
    //   }
    // }
    // &.icon-2 {
    //   &:before {
    //     content: url(../../img/busemployee.png);
    //   }
    // }
    // &.icon-3 {
    //   &:before {
    //     content: url(../../img/joreteam.png);
    //   }
    // }
    // &.icon-4 {
    //   &:before {
    //     content: url(../../img/superstockist.png);
    //   }
    // }
    // &.icon-5 {
    //   &:before {
    //     content: url(../../img/regionalstockist.png);
    //   }
    // }
    // &.icon-6 {
    //   &:before {
    //     content: url(../../img/districtstockist.png);
    //   }
    // }
    // &.icon-7 {
    //   &:before {
    //     content: url(../../img/decenterlizedstockist.png);
    //   }
    // }
    // &.icon-8 {
    //   &:before {
    //     content: url(../../img/logisticspartner.png);
    //   }
    // }
    // &.icon-9 {
    //   &:before {
    //     content: url(../../img/deliverypartner.png);
    //   }
    // }
    // &.icon-10 {
    //   &:before {
    //     content: url(../../img/manufacturer.png);
    //   }
    // }
    // &.icon-11 {
    //   &:before {
    //     content: url(../../img/productsupplier.png);
    //   }
    // }
    // &.icon-12 {
    //   &:before {
    //     content: url(../../img/serviceprovider.png);
    //   }
    // }
    // &.icon-13 {
    //   &:before {
    //     content: url(../../img/partner.png);
    //   }
    // }
    // &.icon-14 {
    //   &:before {
    //     content: url(../../img/eminentpanel.png);
    //   }
    // }
  }
  .ctgry-count {
    padding: 1px 10px;
    background-color: #f0ad4e;
    border-radius: 3px;
    color: #ffff !important;
  }
}

@-webkit-keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.hvr-icon-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-pop .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-pop:hover .hvr-icon,
.hvr-icon-pop:focus .hvr-icon,
.hvr-icon-pop:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pop;
  animation-name: hvr-icon-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
