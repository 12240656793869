.table-default {
  .table {
    border: 0;
    .checkbox-primary {
      &:before {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #2c27380a;
        border: 1px solid #dbe2ea;
      }
    }

    thead {
      th {
        height: 46px;
        background-color: #00d2d3;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        border: none;
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
    tbody {
      td {
        border: none;
        background-color: $white;
        .tbl-edit-drop {
          .btn {
            border: none;
            background: none;
            .fa {
              color: #818181;
            }
          }
        }
      }
    }
  }
}

.custom-table {
  table {
    th,
    td {
      font-size: 14px;
      vertical-align: middle;
    }
    thead {
      tr th {
        &:first-child {
          width: 25px;
        }
      }
    }

    tr {
      &:nth-of-type(odd) {
        background-color: rgb(252, 252, 252);
      }
    }
  }
  .tbl-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a {
      float: right;
      margin-left: 5px;
      cursor: pointer;

      &.tbd-view {
        &:before {
          content: "\f065";
        }
      }
      &.tbd-edit {
        &:before {
          content: "\f040";
        }
      }
      &.tbd-delete {
        &:before {
          content: "\f1f8";
        }
      }

      &:before {
        font-family: "FontAwesome";
        width: 30px;
        height: 30px;
        display: flex;
        border: 1px solid #a9a7bd;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
      }
    }
  }
}
.avatar-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tbl-avatar-sm {
    overflow: hidden;
    width: 35px;
    height: 35px;
    border: 1px solid #cccc;
    border-radius: 50px;
    padding: 3px;
    margin-right: 5px;
    img {
      width: 100%;
    }
  }
}

.table-b0 {
  border: none;
  td {
    border: none;
  }
}

.table-seperate {
  border: none;
}

.table-seperate {
  .table-box {
    background-color: #fff;
  }
  table {
    border: none;
    border-collapse: separate;
    border-spacing: 0em 0.5em;
    td,
    th {
      border: none;
      vertical-align: middle;
    }
    thead {
      th {
        height: 46px;
        background-color: #00d2d3;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
      }
      th:last-child {
        border-top-right-radius: 10px;
      }
      th:first-child {
        border-top-left-radius: 10px;
      }
    }
    tbody {
      td {
        background-color: #ffffff;
        color: #222222;
        font-size: 15px;
        height: 46px;
      }

      td:first-child,
      th:first-child {
        width: 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
    .table-arrow {
      &:before {
        content: url(../../img/arrow-forward.svg);
      }
    }
  }
}

.table-card {
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #dddddd;
    padding: 39px;
    li {
      list-style: none;
      display: inline-block;
      .item {
        h3 {
          color: #000000;
          font-size: 21px;
          line-height: 28px;
          font-weight: 500;
        }
        p {
          color: #000000;
          font-size: 14px;
          line-height: 28px;
          font-weight: 400;
        }
      }
    }
  }
  .table-notify {
    margin: 30px 0px;
    position: relative;
    padding-left: 70px;
    h2 {
      color: #000000;
      font-size: 25px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    span {
      color: #acacac;
      font-size: 12px;
      line-height: 28px;
      font-weight: 400;
    }
    // &:before {
    //   content: url(../../img/tbl-notify.png);
    //   position: absolute;
    //   left: 0px;
    // }
  }
  .user-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: baseline;
    .user-img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 50px;
        height: 50px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .user-cnt {
      h2 {
        color: #000000;
        font-size: 25px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      p {
        color: #acacac;
        font-size: 12px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
  .attachment {
    background-color: #dbe2ea;
    position: relative;
    padding: 8px 16px 8px 36px;
    border-radius: 50px;
    color: #756f86;
    margin: 18px 0px;
    display: inline-flex;
    &:before {
      position: absolute;
      left: 12px;
      content: url(../../img/Icon-attachment.svg);
      top: 10px;
    }
  }
}
.review-point {
  .input-group {
    input {
      border-radius: 50px !important;
      padding-right: 65px;
    }
    .uploadLabel {
      position: absolute;
      right: 18px;
      top: 9px;
      input.uploadButton {
        display: none !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .width-50 {
    width: 50%;
  }
}

.table-seperate.nml-table table tbody td:first-child,
.table-seperate.nml-table table tbody th:first-child {
  width: auto !important;
}
