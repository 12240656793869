$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff0000 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$white: #ffffff !default;
$white: #ffffff !default;

// Valiable color start

$login-plchdr: #bfbfbf;
$login-bg: #f4f9db;
$login-label: #666b4b;
$login-border: #bfbfbf;
$login-btn: #aeca0c;
// Valiable color end

$crl-2: #6566b2 !default;
$light-b-color: #f7f9fa !default;

$checkbox-p: #aeca0c !default;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");
$white: #fff !default;
$text-violet: #8918e6 !default;
$clr-light: #919191 !default;
$clr-prymary-1: #00d2d3 !default;
$green: #1d9634 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #222222 !default;
$light-text: #756f86 !default;
$placeholder: #7c9cbf !default;

$btn-primary-b: #0880ae !default;
$btn-primary-g: #00d2d3 !default;
$btn-primary-v: #8918e6 !default;
$primary-v: #8918e6 !default;
$light-blue-bg: #ebf4f8 !default;
$BgWhite: #ffffff !default;
