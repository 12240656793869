.card-body {
  padding: 30px;
}
.info-error {
  p {
    font-size: 22px;
    font-weight: 500;
    color: #ff0000;
  }
}

/*----------upload-file----------*/
.upload-file {
  background: #f5f6f7;
  padding: 25px;
  text-align: center;
}
.upload-file {
  &.mr-tb-50 {
    margin: 50px 0px;
  }
  p {
    color: $light-text;
    font-size: 16px;
  }
  .uploadButton {
    display: none;
  }
  .uploadLabel {
    box-shadow: 0px 2px 4px #2c273814;
    display: inline-block;
    padding: 11px 20px;
    cursor: pointer;
    border-radius: 5px;
    background-color: $clr-prymary-1;
    color: #ffffff;
    margin: 10px 0px;
  }
}
.upload-fil {
  &.mr-tb-50 {
    margin: 50px 0px;
  }
  p {
    color: $light-text;
    font-size: 16px;
  }
  .uploadButton {
    display: none;
  }
  .uploadLabel {
    box-shadow: 0px 2px 4px #2c273814;
    display: inline-block;
    padding: 11px 20px;
    cursor: pointer;
    border-radius: 0px;
    background-color: $clr-prymary-1;
    color: #ffffff;
    margin: 2px 0px;
  }
}
.uploadfile-list {
  h3 {
    color: $light-text;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
  .verified-icon {
    &:after {
      font-family: "FontAwesome";
      content: "\f058";
      padding-left: 15px;
    }
  }
  .verify-btn {
    padding: 7px 30px;
  }
  .table {
    td {
      color: #7c9cbf;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      vertical-align: middle;
      padding: 15px 0px;
      &:last-child {
        text-align: right;
      }
    }
  }
}

.Form-blk {
  .form-label {
    text-align: left;
    letter-spacing: 0px;
    color: #756f86;
    opacity: 1;
    font-size: 15px;
    line-height: 34px;
    font-weight: 500;
  }
  .custom-checkbox {
    .custom-control-label {
      color: #756f86;
      opacity: 1;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.form-group {
  .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #2c27380a;
    border: 1px solid #dbe2ea;
    opacity: 1;
    color: #000;
  }
  .form-label {
    text-align: left;
    letter-spacing: 0px;
    color: #756f86;
    opacity: 1;
    font-size: 15px;
    line-height: 34px;
    font-weight: 500;
    margin: 2px 0px;
  }
}

ul {
  &.chkbx-li {
    li {
      padding-right: 15px;
    }
  }
}

.user-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: baseline;
  .user-img {
    overflow: hidden;
    margin-right: 20px;
    img {
      width: 50px;
      height: 50px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
.Box-round {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.cardBorder {
  border-radius: 15px;
}
.sendMoney {
  background-color: #ffffff;
  padding: 30px;

  .pay-type {
    background-color: #00d2d3;
    border: 1px solid #00d2d3;
    color: #ffffff;
    border-left: 0px;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.Or_hldr {
  .col-md-8 {
    margin: 0 auto;
  }
  .Order-Received {
    text-align: center;
    background-color: $white;
    padding: 50px;
    margin: 50px 0px;
  }
}

.amnt-pay {
  line-height: 18px;
  font-weight: 600;
  color: $black;
  display: flex;
  align-items: center;
  &.justify-content-s-b {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: end;
  }
}

.amnt-pay {
  margin: 15px 0px;
  .fa_exclamation-a {
    &:after {
      line-height: 18px;
      color: #c0cadd;
    }
  }
}

.c_profile {
  position: relative;
  width: 200px;
  height: 200px;

  .c_profile-img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    overflow: hidden;
    img {
      width: 200px;
      height: 200px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .fileUpload {
    position: absolute;
    overflow: hidden;
    /* margin: 20px 0px; */
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #7c9cbf;
    color: #ffffff;
    justify-content: center;
    border-radius: 50px;
    bottom: 0px;
    right: 10px;

    input {
      &.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
      }
    }
  }
}
.p-head {
  color: #756f86;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 15px;
}

.modal-head-icon {
  padding-left: 40px;
  position: relative;
  &:before {
    position: absolute;
    left: 0px;
  }

  &.model-alert-icon {
    &:before {
      // content: url(../../img/alert-icon.svg);
    }
  }
}

.Mc_flex {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  .item-1 {
    display: flex;
    align-items: center;
    margin-right: 8px;
    p {
      margin-right: 8px;
    }
    span {
      font-weight: 600;
    }
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-link {
  padding-top: 0.75rem;
  font-size: 0.875rem;
}
