/**integrated changes helna***/
.lnd-rate-holder ul {
    margin: 0px;
    padding: 0px;
  }
  .lnd-rate-holder ul li {
    list-style: none;
    position: relative;
  }
  .lnd-rate-holder ul li.lnd-rate-underline {
    border-bottom: 1px solid #C3D1D4;
  }
  .lnd-rate-holder ul li.lnd-rate-item {
    padding: 7px 0px 7px 15px;
    margin-left: 43px;
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    font-weight: 400;
  }
  .lnd-rate-holder ul li.lnd-rate-item:after {
    position: absolute;
    content: "";
    height: 75%;
    width: 1px;
    background-color: #C3D1D4;
    left: 0px;
    top: -16px;
  }
  .lnd-rate-holder ul li.lnd-rate-item.lnd-left-dots:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #C3D1D4;
    left: -4px;
  }
  .lnd-rate-holder ul li.lnd-rate-item.lnd-close-icon:before {
    position: absolute;
    // content: url("../img/hide_clk.jpg");
    left: -10px;
    z-index: 111;
  }
  .lnd-rate-holder ul li.lnd-rate-item.lnd-text-bold h3 {
    font-size: 16px;
    font-weight: 600;
  }
  .lnd-rate-holder ul li.lnd-rate-item.lnd-rate-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .buy-authentic .btn-icon:after {
    content: url(../img/btn-right-arrow.svg);
    margin-left: 18px;
  }
  .buy-authentic .form-control {
    height: 60px !important;
  }
  
  .btn-icon:after {
    content: url(../img/btn-right-arrow.svg);
    margin-left: 18px;
  }
  
  .sub-head.sub-h-b.sub-h-clr-w h3 {
    background-color: #FFFFFF;
  }
  .sub-head.sub-h-b.sub-h-clr-g h3 {
    background-color: #ebf4f8;
  }
  
  .cashtree .sub-head.sub-h-b.sub-h-clr-w h3 {
    background-color: #EBF4F8 !important;
  }
  .cashtree .cashtree-bdy-img {
    padding: 90px 0px !important;
  }
  .cashtree .customer-id {
    font-size: 18px;
    font-weight: 600;
    color: #00D2D3;
    background-color: #CAF5F5;
    padding: 10px 20px;
    margin: 18px 0px;
    display: inline-block;
  }
  .cashtree textarea {
    height: 100px !important;
  }
  .cashtree .form-group .upload-item-verify {
    position: relative;
  }
  .cashtree .form-group .upload-item-verify a {
    position: absolute;
    right: 12px;
    top: 11px;
  }
  .cashtree .form-group .upload-item-verify a.verified-icon:after {
    font-family: "FontAwesome";
    content: "\f058";
    padding-left: 15px;
  }
  .cashtree .form-group .upload-item-verify a.upload-icon:after {
    font-family: "FontAwesome";
    content: "\f093";
    padding-left: 15px;
  }
  .cashtree .sub-h-gray h3 {
    background-color: #F8F8F8 !important;
  }
  
  .payer-bank-details .form-control {
    height: 60px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.0392156863);
    box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.0392156863);
    border: 1px solid #DBE2EA;
    opacity: 1;
    color: #000;
    font-size: 14px;
  }
  .payer-bank-details .row {
    --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--bs-gutter-y) * -1);
      margin-right: calc(var(--bs-gutter-x) * -.5);
      margin-left: calc(var(--bs-gutter-x) * -.5);
  }
  .buy-login .loginLeftImg {
    text-align: center;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .buy-login .loginLeftImg img {
    max-width: 80%;
  }
  .buy-login .form-inner .form-group .form-label {
    text-align: left;
    letter-spacing: 0px;
    color: #756F86;
    opacity: 1;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 2px 0px !important;
  }
  .buy-login .form-inner .login-head h1 {
    font-size: 36px;
    color: #222222;
    font-weight: 600;
  }
  .btn.btn-primary-v {
    background-color: #8918E6;
    color: #FFFFFF;
  }
  .btn.btn-primary-v-outlin {
    border: 1px solid #8918E6;
    color: #8918E6;
  }
  .btn.btn-primary-b-g {
    border: 1px solid #00D2D3;
    color: #00D2D3;
    background-color: #FFFFFF;
  }
  .btn.btn-primary-b-g-v {
    border: 1px solid #8918E6;
    color: #8918E6;
    background-color: #FFFFFF;
  }
  .authentication .btn {
    width: 150px;
  }
  .landig-page .buyEx-banner {
    background-image: url(../img/home-bg.png);
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0px;
  }
  .buyEx-banner .banner-cnt h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 2rem;
  }
  .buyEx-banner .banner-cnt p {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 3rem;
  }
  .buyEx-banner ul.uldiv {
    margin: 0px;
    padding: 0px;
  }
  .buyEx-banner ul.uldiv li {
    list-style: none;
    display: inline-block;
  }
  .buyEx-banner ul.uldiv.banner-sub-hd a {
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      padding-right: 15px;
  }
  .landig-page .custom-navbar .navbar-nav li.nav-item a.nav-link {
    color: #131D31;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }
  .lnd-yellow-line {
    position: relative;
    padding-bottom: 15px;
  }
  .lnd-yellow-line:after {
    position: absolute;
    right: 0px;
    bottom: -30px;
    content: url(../img/bannerhead-icon.png);
  }
  
  .banner-tap .form-control, .banner-tap .form-select {
    height: 60px !important;
    border: 1px solid #C3D1D4;
  }
  ul.uldiv.discover-more a {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 400;
  }
  ul.uldiv.discover-more .btn.btn-secondary {
    background-color: #FFFFFF !important;
    color: #131D31 !important;
  }
  
  .buyEx-banner .banner-tap .nav-tabs .nav-link {
    padding: 16px 30px;
    border: none;
    color: #9e43dd;
    font-weight: 600;
    font-size: 14px;
    background: #670FA3;
    width: 50%;
    text-align: center;
  }
  .buyEx-banner .banner-tap .nav-tabs .nav-link:first-child {
    border-radius: 10px 0px 0px 0px;
  }
  .buyEx-banner .banner-tap .nav-tabs .nav-link:last-child {
    border-radius: 0px 10px 0px 0px;
  }
  .buyEx-banner .banner-tap .nav-tabs .nav-link.active {
    background: #FFFFFF;
    color: #222222;
  }
  
  .landig-page .partnership-holder .partnership-wrap {
    text-align: center;
  }
  .landig-page .partnership-holder .partnership-wrap ul.uldiv li {
    padding: 0px 34px;
  }
  .landig-page .partnership-holder .partnership-wrap p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
  .landig-page .partnership-holder .partnership-wrap p span {
    color: #8918E6;
  }
  ul.uldiv li {
    list-style: none;
    display: inline-block;
  }
  .landig-page .partnership-holder {
    padding: 50px 20px;
  }
  .landig-page .lnd-blk-clr-1 {
    background-color: #F8F8F8;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card {
    background: none;
    border: none;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-body {
    padding: 10px;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .collapse.show {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-header {
    background: none;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .landig-page .lnd-blk-holder {
    padding: 80px 20px;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-header h5 {
    font-weight: 600;
    font-size: 15px;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-header .btn-link {
    text-decoration: none;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-header .btn-link:before {
    content: "\f068";
    font-family: "FontAwesome";
    color: #131D31;
  }
  .landig-page .lnd-blk-holder .lnd-accordion .card .card-header .btn-link.collapsed:before {
    content: "\f067" !important;
    font-family: "FontAwesome";
    color: #131D31;
  }
  .inner-box-header.Order-Review:after {
    content: url(../img/img-big.png) !important;
    position: absolute;
    right: 0px;
    top: 65px;
  }
  
  .cashtree .customer-id {
    font-size: 18px;
    font-weight: 600;
    color: #00D2D3;
    background-color: #CAF5F5;
    padding: 10px 20px;
    margin: 18px 0px;
    display: inline-block;
  }
  .mandatory {
    color: #FF0000;
  }
  .landig-page .owl-carousel .blk-carousel {
    position: relative;
    padding: 65px 0px;
  }
  .landig-page .owl-carousel .blk-carousel:after {
    content: url("../img/carosl-top-1.png");
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .landig-page .owl-carousel .blk-carousel:before {
    content: url("../img/carosl-btm.png");
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .landig-page .owl-carousel .blk-carousel h4 {
    font-size: 25px;
    font-weight: 500;
  }
  .landig-page .owl-carousel .blk-carousel p {
    font-size: 18px;
    font-weight: 400;
  }
  .landing-block-clr {
    background-color: #F8F8F8;
  }
  
  .landig-page .lnd-blk-head {
    margin-bottom: 20px;
  }
  .landig-page .lnd-blk-head h2 {
    font-size: 35px;
    font-weight: 500;
  }
  .landig-page .lnd-blk-head p {
    font-size: 18px;
    font-weight: 400;
  }
  .landig-page .lnd-blk-head.text-center {
    text-align: center;
  }
  .landig-page .vison-blk .v-content .v-item {
    margin-bottom: 20px;
    position: relative;
    padding: 0px 0px 20px 50px;
    border-bottom: 1px solid #C3D1D4;
  }
  .landig-page .vison-blk .v-content .v-item:before {
    content: url("../img/vision-icon.png");
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .landig-page .vison-blk .v-content .v-item h3 {
    font-size: 22px;
    font-weight: 500;
  }
  .landig-page .vison-blk .v-content .v-item p {
    font-size: 18px;
    font-weight: 400;
  }
  
  .landig-page .lnd-application .application-b-right {
    position: relative;
  }
  .landig-page .lnd-application .application-b-right:before {
    content: "";
    position: absolute;
    height: 80%;
    width: 2px;
    background-color: #C3D1D4;
    position: absolute;
    right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 15px;
    bottom: 0px;
    vertical-align: middle;
  }
  .landig-page .lnd-application .application-b-right:after {
    content: "";
    position: absolute;
    height: 25%;
    width: 2px;
    background-color: #7C0FC7;
    position: absolute;
    right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 15px;
    bottom: 0px;
    vertical-align: middle;
  }
  .landig-page .lnd-application .lnd-blk-head h2 {
    font-size: 28px;
    font-weight: 500;
  }
  .landig-page .lnd-application .lnd-blk-head p {
    font-size: 15px;
    font-weight: 400;
  }
  .landig-page .lnd-application .application-content ul {
    margin: 0px;
    padding: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
  }
  .landig-page .lnd-application .application-content ul li {
    list-style: none;
    display: inline-block;
    /*margin: 0px -13px;*/
  }
  .landig-page .lnd-application .application-content ul li a.avatar-item {
    margin: 0px -10px;
    width: 62px;
    height: 62px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    border: 2px solid #ffffff;
    border-radius: 50px;
  }
  .landig-page .lnd-application .application-content ul li a.avatar-item img {
    width: 62px;
    height: 62px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
  }
  .landig-page .lnd-application .application-content ul li .avatar-item-cnt {
    margin-left: 18px;
  }
  .landig-page .lnd-application .application-content ul li .avatar-item-cnt h1 {
    font-size: 22px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }
  .landig-page .lnd-application .application-content ul li .avatar-item-cnt p {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
  }
  .landig-page .lnd-application .application-content .application-item {
    margin-bottom: 20px;
    position: relative;
    padding: 0px 0px 20px 86px;
  }
  .landig-page .lnd-application .application-content .application-item.application-icon-1:before {
    content: url("../img/application-1.png");
  }
  .landig-page .lnd-application .application-content .application-item.application-icon-2:before {
    content: url("../img/application-2.png");
  }
  .landig-page .lnd-application .application-content .application-item.application-icon-3:before {
    content: url("../img/application-3.png");
  }
  .landig-page .lnd-application .application-content .application-item.application-icon-4:before {
    content: url("../img/application-4.png");
  }
  .landig-page .lnd-application .application-content .application-item:before {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .landig-page .lnd-application .application-content .application-item h3 {
    font-size: 20px;
    font-weight: 500;
  }
  .landig-page .lnd-application .application-content .application-item p {
    font-size: 15px;
    font-weight: 400;
  }
  .landig-page .ing-bg-2 {
    background-image: url(../img/home-bg-2.png);
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .landig-page .ing-bg-2 .lnd-low-cost h2 {
    font-size: 35px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2rem;
  }
  .landig-page .ing-bg-2 .lnd-low-cost p {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
  }
  .landig-page .ing-bg-2 .lnd-low-cost .btn {
    margin-top: 20px;
  }
  .lnd-news-blogs .card-img-top {
    margin-bottom: 15px;
  }
  .lnd-news-blogs .card {
    background: none;
    border: none;
  }
  .lnd-news-blogs .card .card-body {
    padding: 0px;
  }
  .lnd-news-blogs .card .card-body p.lnd-name {
    font-size: 13px;
    line-height: 28px;
    font-weight: 400;
  }
  .lnd-news-blogs .card .card-body h3 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: #222222;
    padding: 7px 0px;
  }
  .lnd-news-blogs .card .card-body p.lnd-date {
    padding-left: 25px;
    position: relative;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
  }
  .lnd-news-blogs .card .card-body p.lnd-date:before {
    content: url("../img/lnd-date-icon.png");
    position: absolute;
    left: 0px;
  }
  /*---------lnd-business---------------*/
  /*
  .buyEx-bg2{
    background-image: url("../img/color-bg-2.png"); 
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }*/
  .lnd-business .border-tb {
    border-top: 1px solid #C3D1D4;
    border-bottom: 1px solid #C3D1D4;
    margin: 15px 0px;
  }
  .lnd-business .btn {
    margin-top: 15px;
  }
  .lnd-business .business-test {
    padding: 15px 0px;
  }
  .lnd-business .business-test.last-child {
    text-align: right;
  }
  .lnd-business .business-test.first-child {
    text-align: left;
  }
  .lnd-business .business-test h2 {
    font-size: 79px;
    line-height: 92px;
    font-weight: 600;
  }
  .lnd-business .business-test p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
  
  /*--------------bank-less-holder start----------------*/
  .bank-less-holder .right-img img {
    margin-top: -168px;
  }
  .bank-less-holder .right-img p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .bank-less-holder .row-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bank-less-holder .bank-less.white-line:after {
    content: url("../img/line-white-icon.svg");
    padding-top: 20px;
    display: block;
  }
  .bank-less-holder .bank-less h2 {
    font-size: 80px;
    line-height: 92px;
    font-weight: 800;
    color: #FFFFFF;
  }
  .bank-less-holder .bank-less p {
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
    color: #FFFFFF;
  }
  
  /*-------footer-page---start----*/
  .lnd-footer-bg {
    background-image: url(../img/footer-bg-1.png);
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 64px 0px;
  }
  .lnd-footer-bg .footer-1 {
    margin-bottom: 195px;
  }
  .lnd-footer-bg .footer-wrap .f-head {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .lnd-footer-bg .footer-wrap ul li a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #131D31;
    line-height: 32px;
  }
  .lnd-footer-bg .contact-1 ul {
    margin-bottom: 15px;
  }
  /**container**/
  @media (min-width: 1200px){
  .container1 {
      max-width: 1320px !important;
  }
  }
  .intl-tel-input.allow-dropdown .selected-flag {
    float: right;
}
.intl-tel-input {
  width: 100%;
}

@media(max-width:500px){
  .buyEx-banner ul.uldiv {
    margin: 0px;
    padding: 0px;
    text-align: center;
}
}