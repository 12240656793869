.order-detail{
	&.card{
		border:none;
				border-top-right-radius:10px;
		border-top-left-radius:10px;
	}
	.card-header{
		background-color:#DAE8EE;
		color: #000000;
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;
		border-top-right-radius:10px;
		border-top-left-radius:10px;
		border:none
	}
	.item-holder{
		label{
			color: #728191;
			font-size: 15px;
			font-weight: 500;
			line-height: 17px;
			margin: 1px 0px;
		}
		p{
			color: #2C2738;
			font-size: 18px;
			font-weight: 600;
			line-height: 17px;	
		}
	}
	table{

		td{
			text-align: left;
		}
	}
}
.card-table{
	table{
		margin-bottom: 0px;
	}
	.card-b0{
		padding: 0px;
	}
}
.mr-20{
	margin: 20px 0px;
}

.Policy-holder{
	h4{
		font-size: 35px;
		font-weight: 600;
		line-height: 28px;
	}
.Policy-blk{
	h3{
		font-size: 22px;
		font-weight: 600;
		line-height: 28px;
	}
	p{
		font-size: 18px;
		color: #848484;
		line-height: 28px;
	}

}
.mr-auto-0{
		margin: 0 auto;
	}
.modal-footer, .modal-header{
	border:none;
}
}