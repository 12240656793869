.download_btn {
  background: #f5f6f7;
  padding: 15px 0px;
  &.Icon {
    .uploadLabel {
      padding-left: 45px;
      position: relative;
      background-color: #0880ae !important;
      &:before {
        position: absolute;
        left: 12px;
      }
    }
    &.Icon-download {
      .uploadLabel {
        &:before {
          content: url(../../img/nav-menu/CA_Users-h.svg);
        }
      }
    }
  }

  .uploadButton {
    display: none !important;
  }
  .uploadLabel {
    box-shadow: 0px 2px 4px #2c273814;
    display: inline-block;
    padding: 11px 20px;
    cursor: pointer;
    border-radius: 5px;
    background-color: $clr-prymary-1;
    color: #ffffff;
    margin: 10px 0px;
  }
}
.mr-grid {
  padding: 25px;
  background-color: #7c9cbf;
  color: $white;
  border-radius: 15px;
  .aling-right {
    text-align: right;
  }
  .mr-cnt {
    padding: 15px 0px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    span {
      font-weight: 600;
    }
  }
  h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
  }
}

.table-card {
  margin: 20px 0px;
  .form-inline {
    .form-control {
      width: 80%;
    }
  }
  .table-card-border {
    border: 1px solid #dddddd;
    padding: 39px;
  }
  .item {
    margin: 15px 0px;
    h3 {
      color: #000000;
      font-size: 21px;
      line-height: 28px;
      font-weight: 500;
    }
    span {
      color: #000000;
      font-size: 15px;
      line-height: 28px;
      font-weight: 500;
    }
  }
  .user-holder {
    margin: 15px 0px;
    h2 {
      color: #000000;
      font-size: 25px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    span {
      color: #acacac;
      font-size: 12px;
      line-height: 28px;
      font-weight: 400;
    }
  }
}

.info-success {
  p {
    font-size: 20px;
    color: #00d300;
  }
}
