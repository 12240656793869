h2.accordion-header button {
    padding: 0px;
    background-color: #fff;
    border: 0;
    width: 100%;
    text-align: left;
}
i.fa-minus {
    font-size: 15px;
    color: #000;
}