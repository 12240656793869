.m-tb-10 {
  margin: 10px;
}
.m-tb-30 {
  margin: 30px;
}
.buyEx-banner {
  background: transparent linear-gradient(91deg, #705bed 0%, #00c6cc 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  padding-bottom: 80px;
  .bannerInner {
    padding: 50px 0px;
  }
  .banner-tap {
    .nav-tabs {
      .nav-link {
        padding: 16px 30px;
        border: none;
        color: #4b5ed1;
        font-weight: 600;
        font-size: 16px;
        background: rgba(255, 255, 255, 0.24);
        &:first-child {
          border-radius: 20px 0px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 20px 0px 0px;
        }
        &.active {
          background: $white;
          color: #222222;
        }
      }
    }
    .amnt-pay {
      line-height: 18px;
      font-weight: 600;
      color: $black;
      display: flex;
      align-items: center;
      &.justify-content-s-b {
        justify-content: space-between;
      }
      &.justify-content-end {
        justify-content: end;
      }
    }

    .amnt-pay {
      margin: 15px 0px;
      .fa_exclamation-a {
        &:after {
          line-height: 18px;
          color: #c0cadd;
        }
      }
    }
    .pay-type {
      background-color: $btn-primary-g;
      border: 1px solid $btn-primary-g;
      color: $white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .tab-content {
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 10px 20px 50px #0000001a;
      border-radius: 0px 20px 20px 20px;
      label {
        color: $light-text;
        margin-bottom: 5px;
      }
      .tab-pane {
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 10px 20px 50px #0000001a;
        border-radius: 0px 0px 20px 20px;
      }
    }
    .nav-tabs {
      border: none;
    }
  }
  .banner-cnt {
    padding-top: 42px;
    h1 {
      font-size: 50px;
      line-height: 60px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 2rem;
    }
    p {
      font-size: 25px;
      line-height: 38px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 3rem;
    }
  }
}

.buy-list {
  background-color: $primary-v;
  padding: 25px 15px 32px 15px;
  position: relative;
  border-radius: 10px;
  margin-top: -65px;
  margin-bottom: 70px;
  .arrow-left {
    position: absolute;
    left: 16px;
    top: 0px;
    bottom: 0px;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
  }
  .arrow-right {
    position: absolute;
    right: 16px;
    top: 0px;
    bottom: 0px;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
  }
  ul {
    margin: 0px 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0px 25px;
      border-right: 1px solid #ccc;
      &:last-child {
        border: none;
      }
    }
  }
  .buy-list-box {
    h3 {
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      color: $white;
    }
    p {
      text-align: left;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: $white;
      padding: 5px 0px;
    }
  }
  .ratecard {
    position: absolute;
    text-align: center;
    left: 0px;
    right: 0px;
    bottom: -21px;
  }
}

/*--------------buyEx-testimonial start---------------*/
.cmn-head {
  padding-bottom: 50px;
  &.line-icon {
    h3 {
      position: relative;
      &:before {
        text-align: center;
        left: 0px;
        right: 0px;
        top: 29px;
        position: absolute;
      }
      &.white-line {
        &:before {
          content: url("../../img/line-white-icon.svg");
        }
      }
      &.black-line {
        &:before {
          content: url("../../img/line-color-icon.svg");
        }
      }
    }
    p {
      padding-top: 38px;
    }
  }
  h3 {
    font-size: 45px;
    line-height: 38px;
    font-weight: 600;
    position: relative;
  }
  p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #756f86;
  }
}

.buyEx-testimonial {
  .testimornial-grid {
    background: $light-blue-bg url("../../img/icon-testimonial.png") right top
      no-repeat;
    border: 1px solid #dddddd;
    padding: 20px;
    .userwrap {
      position: relative;
      padding-left: 79px;
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      .user-img {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50px;
        display: flex;
        position: absolute;
        left: 0px;
      }
      .user-name {
        font-size: 30px;
        line-height: 34px;
        font-weight: 600;
        max-width: 115px;
      }
    }
    .user-cnt {
      color: $light-text;
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
    }
  }
} /*buyEx-testimonial*/

.buyEx-association {
  background-image: url("../../img/blue-bg.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .associate-carowsil {
    padding: 25px 0px;
    .item {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      img {
        background-color: white;
        object-fit: contain;
        padding: 10px;
        @media (max-width: 767px) {
          width: 510px;
          max-width: 90%;
        }
      }
    }
  }
} /*buyEx-association*/

/*--------------buyEx-testimonial start---------------*/

.buyEx-news-blogs {
  .bg-padding {
    padding-bottom: 200px;
  }
  .news-blog {
    .card {
      background: none;
      border: none;
      .card-img-top {
        height: 260px;
        object-fit: cover;
        @media (max-width: 991px) {
          object-fit: cover;
          height: 160px;
        }
        @media (max-width: 767px) {
          height: 260px;
          object-fit: contain;
        }
      }
      .card-body {
        padding: 0px;
        p {
          font-size: 23px;
          line-height: 28px;
          font-weight: 400;
          padding: 10px 0px 0px 0px;
          &.clr-v {
            color: $text-violet;
          }
          &.clr-light {
            color: $clr-light;
          }
        }
        h3 {
          font-size: 26px;
          line-height: 34px;
          font-weight: 500;
          color: $black;
          padding: 7px 0px;
        }
      }
    }
  }
} /*---------buyEx-news-blogs---------------*/

.buyEx-bg2 {
  background-image: url("../../img/color-bg-2.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*--------------bank-less-holder start----------------*/
.bank-less-holder {
  .right-img {
    img {
      margin-top: -168px;
    }
    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      color: $white;
    }
  }
  .row-center {
    align-items: center;
  }
  .bank-less {
    &.white-line {
      &:after {
        content: url("../../img/line-white-icon.svg");
        padding-top: 20px;
        display: block;
      }
    }
    h2 {
      font-size: 80px;
      line-height: 92px;
      font-weight: 800;
      color: $white;
    }
    p {
      font-size: 26px;
      line-height: 32px;
      font-weight: 400;
      color: $white;
    }
  }
}
/*--------------bank-less-holder end-----------------*/

.cmn-head {
  .light-text {
    color: $white;
  }
  .black-text {
    color: $black;
  }
}
