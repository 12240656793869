// Formsarea




  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder !important;
}

.form-control{
    height: 50px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 4px 8px #2c27380a;
    box-shadow: 0px 4px 8px #2c27380a;
    border: 1px solid #DBE2EA;
    opacity: 1;
    color: #000;
    font-size: 14px;

}
.form-select, .form-control{
      &:focus{
      box-shadow:none;
      outline: none;
      }
}
.typetextarea{
  height: 150px !important;
}
.form-group{
  .form-label{
    color: #756F86;
    opacity: 1;
    font-size: 15px;
    line-height: 34px;
    font-weight: 500;
    margin: 2px 0px;
  }
}