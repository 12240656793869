.location-grid-wrap {
  .l-grid {
    margin: 15px 0px;
    .l-img {
      img {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    .l-cnt {
      text-align: left;
      background-color: #ffffff;
      padding: 15px;
      border-radius: 5px;
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 2px;
      }
      p {
        font-size: 11px;
        font-weight: 400;
        color: #756f86;
        margin-bottom: 5px;
      }
    }
  }
  .cnt-result {
    font-weight: 600;
    font-size: 18px;
    padding: 15px;
  }
  .l-map {
    position: relative;
    text-align: center;
    // background: url(../img/map.jpg);
    min-height: 350px;
    background-position: 100% center;
    display: flex;
    align-items: center;
    justify-content: center;
    .l-grid-map {
      background-color: #fff;
      padding: 15px 15px;
      display: inline-flex;
      max-width: 350px;
      .l-img {
        flex: none;
        width: 150px;
        float: left;
      }
      .l-cnt {
        margin-left: 15px;
        float: left;
      }
    }
  }
}
.left-icon {
  position: relative;
  padding-left: 20px;
  &:before {
    position: absolute;
    left: -14px;
    top: -12px;
  }
  &.km-icon {
    &:before {
      content: url("../../img/location-symbol.png");
      transform: scale(0.5);
    }
  }
}
.Bmr-20 {
}
.CntBody {
  margin: 20px 0px;
}
.Mbtn-20 {
  margin: 20px 0px;
}

.sub-head {
  &.sub-h-b {
    border-bottom: 1px solid #ccc;
    position: relative;
    margin: 40px 0px;
    &.sub-h-clr-w {
      h3 {
        background-color: #f8f8f8;
      }
    }
    &.sub-h-clr-g {
      h3 {
        background-color: #ebf4f8;
      }
    }
    h3 {
      position: absolute;
      left: 0px;
      top: -17px;
      padding-right: 15px;
    }
  }
  h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
  }
}
.form-label {
  color: #756f86;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

/*ce-customer details*/
.ce-v-bank-view {
  background-color: #fff;
  padding: 15px 15px;
  .row {
    align-items: center;
  }
  .l-cnt {
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      line-height: 18px;
      margin-bottom: 10px;
      color: #00d2d3;
    }
    h2 {
      font-size: 26px;
      line-height: 28px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #756f86;
      margin-bottom: 10px;
    }
  }
}

/*Order-Received*/
.Order-Received {
  background-color: #fff;
  padding: 30px;
  h1 {
    text-align: center;
    font-size: 40px;
    color: #222222;
    font-weight: 600;
  }
  h4 {
    font-size: 21px;
    font-weight: 400;
    line-height: 35px;
    margin: 15px 0px;
  }
  p {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: #222222 !important;
    margin: 15px 0px;
  }
}
