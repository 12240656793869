// Slide

#layoutSidenav_nav {
  .sb-sidenav-menu {
    .nav {
      .nav-link {
        svg {
          margin-right: 9px;
        }
      }
    }
  }
  .sb-sidenav-footer {
    .nav-link {
      color: #212529;
      padding-left: 38px;
      position: relative;
      &:hover {
        color: #212529;
      }
      &:before {
        position: absolute;
        left: 8px;
        top: 11px;
      }
      &.icon-8 {
        &:before {
          content: url(../../img/nav-menu/l-icon.svg);
        }
      }
    }
  }
}

.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  #layoutAuthentication_content {
    min-width: 0;
    flex-grow: 1;
  }
  #layoutAuthentication_footer {
    min-width: 0;
  }
}

#layoutSidenav {
  display: flex;
  #layoutSidenav_nav {
    flex-basis: 245px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    z-index: 1038;
    transform: translateX(-245px);
  }
  #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);
    margin-left: -245px;
  }
}
.sb-sidenav-toggled {
  #layoutSidenav {
    #layoutSidenav_nav {
      transform: translateX(0);
    }
    #layoutSidenav_content {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 1037;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-245px);
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -245px;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}
.sb-nav-fixed {
  .sb-topnav {
    z-index: 1039;
  }
  #layoutSidenav {
    #layoutSidenav_nav {
      width: 245px;
      height: 100vh;
      z-index: 1038;

      .sb-sidenav {
        padding-top: 56px;
        .sb-sidenav-menu {
          overflow-y: auto;
          padding: 0px 10px;
        }
      }
    }
    #layoutSidenav_content {
      padding-left: 245px;
      top: 56px;
    }
  }
}
.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
}
.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
  .navbar-brand {
    width: 245px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
  }
  &.navbar-dark {
    #sidebarToggle {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &.navbar-light {
    #sidebarToggle {
      color: #00b8b9;
    }
  }
}
.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  .sb-sidenav-menu {
    .collapse.show {
      display: block;
      background: none;
    }
    flex-grow: 1;
    .nav {
      flex-direction: column;
      flex-wrap: nowrap;
      .sb-sidenav-menu-heading {
        padding: 1.75rem 1rem 0.75rem;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .nav-link {
        display: flex;
        align-items: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
        /*border-bottom: 1px solid #eaeaea;*/
        .sb-nav-link-icon {
          font-size: 0.9rem;
        }
        .sb-sidenav-collapse-arrow {
          display: inline-block;
          margin-left: auto;
          transition: transform 0.15s ease;
        }
        &.collapsed {
          .sb-sidenav-collapse-arrow {
            transform: rotate(-90deg);
          }
        }
      }
      .sb-sidenav-menu-nested {
        flex-direction: column;
      }
    }
  }
  .sb-sidenav-footer {
    padding: 0.75rem;
    flex-shrink: 0;
  }
}
.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
  .sb-sidenav-menu {
    .sb-sidenav-menu-heading {
      color: rgba(255, 255, 255, 0.25);
    }
    .nav-link {
      color: rgba(255, 255, 255, 0.5);
      .sb-nav-link-icon {
        color: rgba(255, 255, 255, 0.25);
      }
      .sb-sidenav-collapse-arrow {
        color: rgba(255, 255, 255, 0.25);
      }
      &:hover {
        color: #fff;
      }
      &.active {
        color: #fff;
        .sb-nav-link-icon {
          color: #fff;
        }
      }
    }
  }
  .sb-sidenav-footer {
    background-color: #343a40;
  }
}
.sb-sidenav-light {
  background-color: #ffffff;
  color: #212529;
  .sb-sidenav-header {
    padding: 0.75rem;
  }

  .sb-sidenav-menu {
    .sb-sidenav-menu-heading {
      color: #adb5bd;
    }
    .nav-link {
      color: #212529;
      padding-left: 38px;
      position: relative;
      &:hover {
        color: #212529;
      }
      &:before {
        position: absolute;
        left: 8px;
        top: 11px;
      }

      &.nav-link-active {
        color: $white;
        background-color: #8918e6;
        border-radius: 15px;

        .sb-nav-link-icon {
          color: $crl-2;
        }
        &.icon-1 {
          &:before {
            content: url(../../img/nav-menu/d-icon-w.svg);
          }
        }
        &.icon-2 {
          &:before {
            content: url(../../img/nav-menu/sm-icon-w.svg);
          }
        }
        &.icon-3 {
          &:before {
            content: url(../../img/nav-menu/cx-icon-w.svg);
          }
        }
        &.icon-4 {
          &:before {
            content: url(../../img/nav-menu/t-icon-w.svg);
          }
        }
        &.icon-5 {
          &:before {
            content: url(../../img/nav-menu/p-icon-w.svg);
          }
        }
        &.icon-6 {
          &:before {
            content: url(../../img/nav-menu/r-icon-w.svg);
          }
        }
        &.icon-7 {
          &:before {
            content: url(../../img/nav-menu/setting-icon-w.svg);
          }
        }
        &.icon-8 {
          &:before {
            content: url(../../img/nav-menu/log-icon-w.svg);
          }
        }

        /*HOSER ICON*/
        &.icon-manage_users {
          &:before {
            content: url(../../img/nav-menu/Manage_User-h.svg);
          }
        }
        &.icon-new_users {
          &:before {
            content: url(../../img/nav-menu/New_Users-h.svg);
          }
        }
        &.icon-archived_users {
          &:before {
            content: url(../../img/nav-menu/Archived_Users-h.svg);
          }
        }
        &.icon-me_rate {
          &:before {
            content: url(../../img/nav-menu/ME_Rate-h.svg);
          }
        }
        &.icon-manage_reports {
          &:before {
            content: url(../../img/nav-menu/Manage_Reports-h.svg);
          }
        }
        &.icon-manage_orders {
          &:before {
            content: url(../../img/nav-menu/Manage_Orders-h.svg);
          }
        }

        &.icon-master_tables {
          &:before {
            content: url(../../img/nav-menu/Master_ables-h.svg);
          }
        }
        &.icon-pb_allocation {
          &:before {
            content: url(../../img/nav-menu/PB_Allocation-h.svg);
          }
        }
        &.icon-create_au {
          &:before {
            content: url(../../img/nav-menu/CA_Users-h.svg);
          }
        }
      }

      &.icon-1 {
        &:before {
          content: url(../../img/nav-menu/d-icon.svg);
        }
      }
      &.icon-2 {
        &:before {
          content: url(../../img/nav-menu/sm-icon.svg);
        }
      }
      &.icon-3 {
        &:before {
          content: url(../../img/nav-menu/cx-icon.svg);
        }
      }
      &.icon-4 {
        &:before {
          content: url(../../img/nav-menu/t-icon.svg);
        }
      }
      &.icon-5 {
        &:before {
          content: url(../../img/nav-menu/p-icon.svg);
        }
      }
      &.icon-6 {
        &:before {
          content: url(../../img/nav-menu/r-icon.svg);
        }
      }
      &.icon-7 {
        &:before {
          content: url(../../img/nav-menu/setting-icon.svg);
        }
      }
      &.icon-8 {
        &:before {
          content: url(../../img/nav-menu/log-icon.svg);
        }
      }
      &.icon-manage_users {
        &:before {
          content: url(../../img/nav-menu/Manage_User.svg);
        }
      }
      &.icon-new_users {
        &:before {
          content: url(../../img/nav-menu/New_Users.svg);
        }
      }
      &.icon-archived_users {
        &:before {
          content: url(../../img/nav-menu/Archived_Users.svg);
        }
      }
      &.icon-me_rate {
        &:before {
          content: url(../../img/nav-menu/ME_Rate.svg);
        }
      }
      &.icon-manage_reports {
        &:before {
          content: url(../../img/nav-menu/Manage_Reports.svg);
        }
      }
      &.icon-manage_orders {
        &:before {
          content: url(../../img/nav-menu/Manage_Orders.svg);
        }
      }

      &.icon-master_tables {
        &:before {
          content: url(../../img/nav-menu/Master_ables.svg);
        }
      }
      &.icon-pb_allocation {
        &:before {
          content: url(../../img/nav-menu/PB_Allocation.svg);
        }
      }
      &.icon-create_au {
        &:before {
          content: url(../../img/nav-menu/CA_Users.svg);
        }
      }

      /*admin web menu bar icon*/
      /*			&.icon-manage_users{
				&:before{
						content: url(../../img/nav-menu/log-icon.svg);
				}
			}*/

      /*admin web menu bar icon*/

      .sb-nav-link-icon {
        color: #adb5bd;
      }
      .sb-sidenav-collapse-arrow {
        color: #adb5bd;
      }
    }
  }
  .sb-sidenav-footer {
    background-color: #e9ecef;
  }
}
.c__nav {
  .navbar-nav {
    .nav-link {
      &:hover {
        background: #f7f9fa;
      }
      .fa {
        color: #9d9fa2;
      }
    }
    .navlink-item {
      color: rgba(0, 0, 0, 0.5);
      padding: 17px 15px !important;
      font-size: 14px;
      float: left;
      margin-right: 2px;
      cursor: pointer;
      border-radius: 0px;
      height: auto;
    }

    .nan-link-profile {
      img {
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
        border: 1px solid #eaeaea;
        object-fit: cover;
      }
    }
  }
  padding: 0px !important;
  .navbar-brand {
    /*		background-color: #f7f9fa;*/
    img {
      max-width: 130px;
    }
  }

  .hmr_btn {
    padding: 16px 20px 16px 20px !important;
    font-size: 14px;
    float: left;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 0px;
    height: auto;
  }
}
.bg-white {
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
}

.collapse {
  &.show {
    .nav-link {
      &.nav-active {
        color: $clr-prymary-1;
      }
    }
  }
}
