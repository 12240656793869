._CBox ._Cbox-input {
  display: none;
}
._CBox ._Cbox-input + ._Cbox-Lbl {
  display: inline-block;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background: url(../../img/check-i.png) no-repeat center;
  overflow: hidden;
}
._CBox ._Cbox-input:checked + ._Cbox-Lbl {
  background: url("../../img/checked-i.png") no-repeat center;
  width: 23px;
  height: 23px;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.custom-checkbox {
  position: relative;
  &.chkbx-left {
    padding-left: 30px;
  }
  .custom-control-input {
    left: 0px;
    &:checked {
      & ~ .custom-control-label {
        &::before {
          background-color: $clr-prymary-1;
          border: 1px solid $clr-prymary-1;
          box-shadow: none;
        }
      }
    }
  }
  .custom-control-label {
    cursor: pointer;
    cursor: pointer;
    letter-spacing: 0px;
    color: #756f86;
    opacity: 1;
    font-size: 15px;
    line-height: 26px;
    font-weight: 500;
    &.checkbox-primary {
      &:before {
        background-color: $white;
        border: 1px solid #756f86;
      }
    }
    &:before {
      width: 1.3rem;
      height: 1.3rem;
      border: 1px solid #bfbfbf;
      border-radius: 0px !important;
      background-color: $white;
      top: 2px;
    }
    &:after {
      width: 1.3rem;
      height: 1.3rem;
      top: 2px;
      background-size: 53% 52%;
    }
  }
}

/*radio button*/
.custom-control {
  padding-left: 1.8rem;
  &.custom-radio {
    .custom-control-label {
      letter-spacing: 0px;
      color: #756f86;
      opacity: 1;
      font-size: 15px;
      line-height: 26px;
      font-weight: 500;
      &:before {
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #2c273814;
        border: 1px solid #dbe2ea;
        width: 1.5rem;
        height: 1.5rem;
        top: 0.08rem;
      }
    }
    .custom-control-input {
      &:checked {
        & ~ .custom-control-label {
          &:before {
            background: #f7f7f7 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 8px #2c27380a;
            border: 2px solid #0880ae;
          }
          &:after {
            top: 7px;
            left: 6px;
            width: 12px;
            height: 12px;
            border-radius: 50px;
            background-color: #0880ae;
            background-image: none;
          }
        }
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider {
    background-color: #0880ae;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
