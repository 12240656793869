@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
html,
body {
  height: 100%;
}

.m-b-10 {
  margin: 10px 0px;
}
.m-b-20 {
  margin: 20px 0px;
}
.m-b-30 {
  margin: 30px 0px;
}
.m-tb-20 {
  margin: 20px 0px;
}
.mtb-30 {
  margin: 30px 0px;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.col-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Montserrat", sans-serif !important;
  color: $black;
  background-color: #ebf4f8;
}
body.landig-page {
  background-color: #f1f4fa !important;
}
.pt-0 {
  padding: 0px;
}
/*
breadcrumb Start*/

.normalheader {
  .hpanel {
    margin-bottom: 0;
    background-color: none;
    border: 0;
    box-shadow: none;
    margin-bottom: 25px;
    .panel-body {
      .m-t-lg {
        margin-top: 30px;
      }
      background: #fff;
      border: 1px solid #eaeaea;
      border-radius: 2px;
      padding: 20px;
      position: relative;
      .m-head {
        h1 {
          font-weight: 300;
          font-size: 30px;
          margin-top: 0px !important;
        }
        small {
          font-size: 15px;
        }
      }
      .hbreadcrumb {
        padding: 2px 0;
        margin-top: 6px;
        margin-bottom: 0;
        list-style: none;
        background-color: #fff;
        font-size: 11px;
        .active {
          color: #9d9fa2;
        }
        li {
          display: inline-block;
        }
        li + li {
          &:before {
            padding: 0 5px;
            color: #34495e;
            content: "/\00a0";
          }
        }
      }
    } /*panel-body End*/
  }
}
/*
breadcrumb Start End*/
.norml-bd-pd-y {
  padding: 15px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  color: $crl-2;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
  &:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  &:not([href]):hover {
    color: inherit;
    text-decoration: none;
  }
}
img {
  vertical-align: middle;
  border-style: none;
}

// Formsarea End

button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}

.float-end {
  float: right !important;
}
.float-start {
  float: left !important;
}

p {
  padding: 0px;
  margin: 0px;
}
ul {
  margin: 0px;
  padding: 0px;
  &.display-block {
    li {
      display: inline-block;
    }
  }
  li {
    list-style: none;
  }
}
.ml-auto {
  margin-left: auto;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.buy-i {
  position: relative;
  &:before,
  &:after {
    font-family: "FontAwesome";
    position: absolute;
  }
  &.fa_exclamation-a {
    padding-right: 20px;
    &:after {
      content: "\f06a";
      right: 0px;
    }
  }
  &.fa_exclamation-b {
    &:before {
      content: "\f06a";
      left: 0px;
    }
  }
}

.d-flex-c {
  align-items: center;
}
.d-flex-sb {
  justify-content: space-between;
}
.light-blue {
  background-color: $light-blue-bg;
}
.cmn-head {
  text-align: center;
}
.bg-padding {
  padding: 60px 0px;
}
.light-text {
  color: $white;
}

/*-----------Footer section-----------start--------*/
.footer-end {
  border-top: 1px solid #e7e6e6;
  padding: 15px 0px;
  p {
    color: $clr-light;
  }
}
.Buy-Footer {
  padding: 45px 0px;
  .footer-wrap {
    p {
      color: $clr-light;
    }
    ._company-dtls {
      .__social-icons {
        a {
          width: 40px;
          height: 40px;
          background: #28abd4;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
        }
      }
    }
    .footer-logo,
    .address {
      margin-bottom: 20px;
    }

    .f-head {
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    ul {
      li {
        padding-top: 10px;
        a {
          font-size: 16px;
          font-weight: 400;
          color: $clr-light;
          text-decoration: none;
        }
      }
    }
  }
}

.bg-light {
  background-color: $white;
}

/*------------------------END----------------------------*/

.bg-white {
  background-color: $BgWhite;
}
._mnd {
  color: $red;
}
.mr-tb {
  margin: 15px 0px;
}
.section-ptb-lg {
  padding: 150px 0px;
}
.btn-mr {
  margin: 20px 0px;
}

.receiv-Box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin: 10px 0px;
  &:after {
    content: url(../../img/forward-arrow.svg);
    position: absolute;
    right: 21px;
  }
  .rbox-left {
    overflow: hidden;
    margin-right: 20px;
    background-color: #00d2d326;
    color: #00d2d3;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rBox-right {
    h3 {
      font-size: 19px;
      line-height: 22px;
      font-weight: 600;
      margin: 0px;
      color: #222222;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      margin: 0px;
      color: #756f86;
    }
  }
}
.inner-page {
  .inner-box-header:after {
    display: none;
  }
}

@media (max-width: 500px) {
  .application-contents {
    background-color: #00d2d3;
    display: none;
    margin-top: 10px;
  }
}
@media (max-width: 500px) {
  .footer-container {
    padding: 0 !important;
    text-align: center;
  }
  .footer-1 {
    padding: 0 !important;
  }
}
@media (max-width: 500px) {
  .testimonial-image {
    margin-top: 25px;
    margin-left: 10px;
  }
  .testimonial-head {
    margin-top: 25px;
  }
}
@media (max-width: 500px) {
  .testimonial-image1 {
    transform: scale(0.8);
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 0px !important;
  }
}
@media (min-width: 510px) {
  .br-tag-class {
    display: none !important;
  }
  .last-list-element {
    display: none;
  }
  .section-banner-hero-down {
    display: none !important;
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .buyEx-banner .banner-cnt h1 {
    font-size: 33px !important;
    line-height: 39px !important;
    margin-bottom: 1rem !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .buyEx-banner .banner-cnt {
    padding-top: 0 !important;
  }
  .buyEx-banner .banner-cnt p {
    padding-top: 25px !important;
    margin-bottom: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .footer-1 {
    text-align: center;
  }
  .copyright1 {
    // margin: 0 !important;
    // margin-bottom: 15px !important;
    // position: absolute;
    // bottom: 0;
    display: none;
  }
  .last-list-element {
    display: block;
  }
  .footer-container {
    height: 850px !important;
  }
  .whatsApp-image {
    margin-left: 110px;
    margin-top: 10px;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .inner-box-header.Hright-img:after {
    content: none !important;
  }
  .cashtree .cashtree-bdy-img {
    padding: 0 !important;
  }
  .cashtree .form-group .upload-item-verify a {
    position: absolute;
    right: 12px;
    top: 11px;
    width: 40%;
  }
  .buy-login .form-section .new-section {
    min-height: 100vh !important;
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    top: 25px !important;
    /* align-items: center; */
    padding: 15px 0 !important;
    background: #ffffff !important;
  }
  .my-image {
    transform: scale(1) !important;
  }
  .description-h2 {
    font-size: 25px !important;
    font-weight: 500;
  }
  .description-image {
    transform: scale(1.3);
  }
  .description-section {
    margin-top: 25px;
  }
  .search-engine-image {
    transform: scale(1.3);
  }
  .loan-h3 {
    font-size: 35px;
  }
  .loan-second-h3 {
    font-size: 20px;
  }
  .footer-disclaimer {
    margin: 0;
    padding: 0 !important;
  }
}

.landig-page .lnd-application .application-b-right:after {
  content: "";
  position: absolute;
  height: 25%;
  width: 2px;
  background-color: #c3d1d4 !important;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 15px;
  bottom: 0px;
  vertical-align: middle;
}
// .footer-li-a{
//   color: "#919191" !important;
// }
@media (min-width: 320px) and (max-width: 500px) {
  .section-banner-hero-up {
    display: none !important;
  }
  .section-banner-hero-down {
    display: block !important;
  }
  .buy-login .form-section .new-section1 {
    min-height: 100vh !important;
    position: relative !important;
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center;
    padding: 15px !important;
    background: #ffffff !important;
  }
}

@media (min-width: 510px) {
  .section-banner-hero-down {
    display: none !important;
  }
  .section-banner-hero-up {
    display: block !important;
  }
  .buy-login .loginLeftImg img {
    max-width: 70% !important;
  }
}

@media (max-width: 710px) {
  .my-image {
    transform: scale(1) !important;
  }
}
.lnd-footer-bg .footer-wrap .social-icons {
  width: 40px;
  height: 40px;
  background: #00d2d2;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

// about start

.buyEx-about-banner {
  background-image: url(../../img/about-bg.png);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 78px 0px;

  .banner-cnt h1 {
    font-size: 53px;
    line-height: 58px;
    font-weight: 550;
    color: #131d31;
    margin-bottom: 2px;
  }
  .banner-cnt h2 {
    font-size: 33px;
    line-height: 60px;
    font-weight: 600;
    color: #131d31;
    // margin-bottom: 2rem;
  }
  .banner-cnt p {
    font-size: 20px;
    line-height: 38px;
    font-weight: 400;
    color: #131d31;
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
}
.btn {
  &.btn-clr-blue {
    background-color: #3b77ce !important;
    color: #ffffff !important;
  }
}
.lnd-blk-tet {
  h2 {
    font-size: 45px;
    line-height: 38px;
    font-weight: 600;
    color: #131d31;
    margin-bottom: 15px;
  }
  p {
    font-size: 18px;
    line-height: 38px;
    font-weight: 400;
    color: #131d31;
    margin-bottom: 1rem;
  }
}
.lnd-blk-awrd {
  h4 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin: 30px 0px;
  }
  h3 {
    font-size: 31px;
    line-height: 44px;
    font-weight: 500;
    color: #fbfbfb;
    margin: 30px 0px;
    border-bottom: 1px solid #ffffff4d;
    padding-bottom: 26px;
  }
}

.landig-page {
  &.abouts {
    .card {
      border: none;
    }
    .ing-bg-3 {
      background-image: url(../../img/about-star.png);
      background-color: #cccccc;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .our-team {
      text-align: center;
      h3 {
        font-size: 26px;
        font-weight: 600;
        color: #131d31;
        margin: 50px 0px;
      }
      .avatar-team {
        margin: 0 auto;
        text-align: center;
        .img {
          width: 150px;
          height: 150px;
          overflow: hidden;
          border-radius: 100%;
          object-fit: cover;
          margin: 0 auto;
          margin-bottom: 15px;
        }
        .text {
          p {
            font-size: 22px;
            line-height: 29px;
            font-weight: 600;
            color: #131d31;
          }
          span {
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            color: #1f1f1f;
          }
        }
      }
    }
    .about-advisory {
      .bg-icon {
        position: relative;
        &:before {
          position: absolute;
          right: 0px;
          bottom: 20%;
          content: url(../../img/advisory2.png);
        }
        &:after {
          position: absolute;
          right: 45px;
          top: -4%;
          content: url(../../img/advisory1.png);
        }
      }
      text-align: center;
      .card {
        border-radius: 15px;
      }
      h3 {
        font-size: 26px;
        font-weight: 600;
        color: #131d31;
        margin-bottom: 45px;
      }
      .avatar-team {
        margin: 0 auto;
        text-align: center;
        .img {
          width: 100px;
          height: 100px;
          overflow: hidden;
          border-radius: 100%;
          object-fit: cover;
          margin: 0 auto;
          margin-bottom: 15px;
        }
        .text {
          margin-bottom: 20px;
          p {
            font-size: 22px;
            line-height: 29px;
            font-weight: 600;
            color: #131d31;
          }
          span {
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            color: #1f1f1f;
          }
        }
      }
      .lnd-blk-tet p {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        color: #131d31;
        margin-bottom: 1rem;
      }
      .lnd-blk-img {
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}

.partnership-holder {
  padding: 50px 20px;
  .partnership-wrap {
    text-align: center;
    ul {
      &.uldiv {
        li {
          padding: 0px 34px;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      span {
        // color: $text-violet;
      }
    }
  }
}

.lnd-blk-clr-1 {
  background-color: #f8f8f8;
}

.lnd-blk-holder {
  padding: 80px 20px;
  .lnd-accordion {
    .card {
      background: none;
      border: none;
      .card-body {
        padding: 10px;
      }
      .collapse {
        &.show {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
      }

      .card-header {
        background: none;
        padding: 10px;
        h5 {
          font-weight: 600;
          font-size: 15px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-link {
          text-decoration: none;
          &:before {
            content: "\f068 ";
            font-family: "FontAwesome";
            //  color: $primary-text;
          }
          &.collapsed {
            &:before {
              content: "\f067 " !important;
              font-family: "FontAwesome";
              //  color: $primary-text;
            }
          }
        }
      }
    }
  }
}

// Award section in About us

.lnd-blk-holder {
  padding: 80px 20px;
  .lnd-accordion {
    .card {
      background: none;
      border: none;
      .card-body {
        padding: 10px;
      }
      .collapse {
        &.show {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
      }

      .card-header {
        background: none;
        padding: 10px;
        h5 {
          font-weight: 600;
          font-size: 15px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-link {
          text-decoration: none;
          &:before {
            content: "\f068 ";
            font-family: "FontAwesome";
            //  color: $primary-text;
          }
          &.collapsed {
            &:before {
              content: "\f067 " !important;
              font-family: "FontAwesome";
              //  color: $primary-text;
            }
          }
        }
      }
    }
  }
}
.Award-section {
  background-color: #0056b3;
  background-image: url(../../img/about-star.png);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.my-teams {
  margin-top: -120px !important;
  text-align: center;
  .container {
    background-color: #fff;
    border-radius: 30px;
    .card {
      border: none;
      border-radius: 30px;
    }
  }
  h3 {
    font-size: 26px;
    font-weight: 600;
    color: #131d31;
    margin: 50px 0px;
  }
  .avatar-team {
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .text {
      p {
        font-size: 22px;
        line-height: 29px;
        font-weight: 600;
        color: #131d31;
      }
      span {
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: #1f1f1f;
      }
    }
  }
}
.my-about-advisory {
  margin-top: -100px !important;
  text-align: center;
  .bg-icon {
    position: relative;
  }
  .bg-icon:before {
    position: absolute;
    right: -71px;
    bottom: 20%;
    content: url(../../img/advisory2.png);
  }
  .bg-icon:after {
    position: absolute;
    right: 45px;
    top: -4%;
    content: url(../../img/advisory1.png);
  }
  .card {
    border-radius: 30px;
    border: none;
  }
  h3 {
    font-size: 26px;
    font-weight: 600;
    color: #131d31;
    // margin-bottom: 45px;
  }
  .avatar-team {
    margin: 0 auto;
    text-align: center;
    .img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .text {
      margin-bottom: 20px;
      p {
        font-size: 22px;
        line-height: 29px;
        font-weight: 600;
        color: #131d31;
      }
      span {
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: #1f1f1f;
      }
    }
  }
  .lnd-blk-tet {
    p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
      color: #131d31;
      margin-bottom: 1rem;
    }
  }
  .lnd-blk-img {
    margin-top: 30px;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      // height: 130%;
    }
  }
}
.my-button {
  background-color: #0056b3 !important;
}
@media (max-width: 500px) {
  .buyEx-about-banner {
    padding: 0 !important;
  }
  .img1 {
    margin-top: 55px !important;
  }
  .my-about-image {
    transform: scale(1) !important;
  }
  .my-about-image {
    margin-top: 0 !important;
    margin-bottom: 40px !important;
  }
  .bg-icon:after {
    position: absolute !important;
    right: 0px !important;
    top: 11% !important;
    content: url(../../img/advisory1.png) !important;
  }
  .my-content{
    text-align: center;
    padding: 0 !important;
  }
  .bg-icon:before {
    position: absolute !important;
    right: -50px !important;
    bottom: 20% !important;
    content: url(../../img/advisory2.png) !important;
  }
  .my-revambing-section{
    text-align: center;
    .my-revanbing-content-section{
      padding: 0 !important;
      font-size: 15px !important;
    }
  }
  .lnd-blk-tet p {
    font-size: 15px !important;
    
}
.my-award-h3{
  font-size: 15px !important;
}
}


body .payment-optn-holder {
  background-color: #00D2D3;
  width: 100%;
  display: inline-block;
  height: 300px;
}
body .payment-option {
  margin-top: -120px;
}
body .payment-option .link-blue {
  color: #3B77CE;
  font-weight: 600;
}
body .payment-option .action-btn {
  text-align: center;
}
body .payment-option .action-btn .btn {
  padding: 10px 50px;
}
body .payment-option .action-btn .btn-primary-b-outlin {
  border: 1px solid #707070;
  color: #707070;
}
body .payment-option .card {
  top: -6rem;
  border-radius: 20px;
  border: none;
}
body .payment-option .grid-card {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 20px rgba(149, 149, 149, 0.1843137255);
          box-shadow: 0px 5px 20px rgba(149, 149, 149, 0.1843137255);
  border-radius: 8px;
  margin: 5px 5px;
}
body .payment-option .lnd-blk.main-header h2 {
  font-size: 25px;
  font-weight: 600;
  padding: 36px 0px;
}
body .payment-option .lnd-blk.sub-header h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 8px 0px;
}
body .payment-option .lnd-blk.sub-header p {
  font-size: 16px;
  font-weight: 400;
  padding: 15px 0px;
}

@media(max-width:500px){
  body .payment-option .lnd-blk.main-header h2 {
    font-size: 19px;
    font-weight: 600;
    padding: 15px 0px;
  }
}

@media(max-width:500px){
  body .payment-option .grid-card-img {
    transform: scale(0.8);
    
  }
  body .payment-option .lnd-blk.sub-header h4 {
    font-size: 15px;
    font-weight: 600;
    padding: 4px 0px;
  }
  .action-btn{
    padding-top: 50px;
  }
  //  .lnd-blk-holder{

  //   padding: 0 !important;
  // }
  // .lnd-business{

  //   padding: 0 !important;
  // }
  body .payment-option .grid-card {
    
    border-radius: 0px;
    
  }
  body .payment-option .card {
    top: -10rem;
    border-radius: 5px;
    border: none;
  }
  body .payment-option .lnd-blk.sub-header p {
    font-size: 14px;
    font-weight: 400;
    padding: 7px 0px;
  }
  .my-container .container{
padding-left: 0 !important;
padding-right: 0 !important;
  }
  
}

.grid-card-img {
  max-width: 100%;
  height: auto; 
}

.copy-tag-mr38 {
  margin: 38px 0px 0px 0px;
}
.copy-tag-mr38 .copy-tag {
  padding: 11px 33px;
  background-color: rgba(195, 239, 216, 0.8);
  display: inline-block;
}





