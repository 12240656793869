button {
  &:focus {
    outline: none;
  }
}
/*-------index page-------*/

@media (min-width: 320px) and (max-width: 768px) {
  .m-b-30 {
    margin: 0px -15px 0px -15px !important;
  }
  .sender-mny-wrap {
    .col-md-10,
    .col-md-7 {
      max-width: 100%;
      flex: 100%;
    }
  }

  .main-layout .layoutSidenav_content {
    padding: 10px;
  }
  .btn.btn-lg {
    width: 100%;
    font-size: 16px;
  }
  .card-body {
    padding: 10px;
  }
  .m-b-30 {
    margin: 10px 0;
  }
  .sub-head.sub-h-b {
    margin: 23px 0;
  }
  .card-header-white {
    padding: 0px 0px 0px 0px;
  }
  .uploadfile-list h3 {
    font-size: 18px;
  }
  .uploadfile-list .table td {
    font-size: 14px;
    padding: 7px 4px;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .form-group .form-label {
    font-size: 13px;
    line-height: 28px;
    margin: 1px 0px;
  }
  .custom-control.custom-radio .custom-control-label {
    font-size: 13px;
  }
  .buy-nav-tabs .nav-tabs .nav-item .nav-link {
    font-size: 15px;
  }

  .buy-login {
    .bg-img {
      min-height: 0px !important;
    }
    .loginLeftImg {
      display: none;
    }
  }

  .buyEx-news-blogs {
    .bg-padding {
      padding-bottom: 20px;
    }
    .news-blog {
      .card {
        margin-bottom: 5px;
        .card-body {
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            &.clr-light {
              padding: 0px;
            }
          }

          h3 {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .c_profile {
    margin: 0 auto;
  }
  .p-head {
    text-align: center;
  }
  .btn {
    width: 100%;
  }
  .c-pagination {
    display: block;
    text-align: center;
    .pagination {
      justify-content: center;
    }
    .btn {
      margin: 5px 0px;
    }
  }
  .sendMoney {
    padding: 15px;
  }
  .section-header {
    .right-filter {
      width: 100%;
      .form-control {
        width: 100%;
        margin-bottom: 5px;
      }
      .input-group-append {
        width: 100%;
        .form-wrap {
          width: 100%;
        }
        .btn {
          width: auto;
          height: 50px;
        }
      }
    }
  }
} /*@media (min-width: 320px) and (max-width: 768px)*/

@media (min-width: 320px) and (max-width: 990px) {
  .section-header {
    .header-1 {
      display: none;
    }
    .Divright {
      display: none;
    }
  }
  .sb-sidenav-header {
    display: none;
  }
  .main-layout .layoutSidenav_content {
    padding: 20px;
    border-radius: 0px;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .sb-topnav #sidebarToggle {
    width: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .buyEx-banner .banner-tap .nav-tabs .nav-link {
    padding: 16px 6px;
    font-size: 14px;
  }
}
@media (min-width: 320px) and (max-width: 749px) {
  .buy-login {
    .loginLeftImg {
      img {
        max-width: 100%;
      }
    }
    .bg-img {
      padding: 114px 0px 0px 0px;
    }
  }
  .inner-box-header.Order-Review:after {
    display: none;
  }
  .Buy-Footer {
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 460px) {
  /*.buy-list{
  overflow: scroll;
}*/
  .right-filter {
    display: block;
  }
  .input-group-append {
    div {
      width: 100%;
    }
    margin: 5px 0px;
    margin-right: 0px !important;
    .form-wrap {
      width: 100%;
    }
    .btn {
      width: auto;
    }
    .form-control {
      margin-right: 5px;
    }
  }
  .buy-navbar {
    .custm-navbar {
      padding: 10px 0px;
    }
  }
  .buyEx-banner .banner-tap .nav-tabs .nav-link {
    padding: 12px 7px;
    font-size: 14px;
    width: 50%;
    text-align: center;
  }
  .buyEx-banner .banner-tap .tab-pane {
    border-radius: 0px !important;
  }

  .buyEx-banner {
    .banner-cnt {
      h1 {
        font-size: 33px;
        line-height: 39px;
        margin-bottom: 1rem;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 2rem;
      }
    }
  }
  .bg-padding {
    padding: 29px 0px;
  }
  .cmn-head h3 {
    font-size: 33px;
  }

  .bank-less-holder {
    .bank-less {
      &.white-line {
        &:after {
          padding-top: 0px;
        }
      }
      h2 {
        font-size: 32px;
        line-height: 43px;
        font-weight: 800;
        color: #ffffff;
      }
    }
    .right-img {
      img {
        margin: 0px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .buyEx-news-blogs {
    .bg-padding {
      padding-bottom: 20px;
    }
  }
}
