

.custm-navbar{
  .navbar-nav{
    .nav-item{
      &:last-child{
        border:none !important;
      }
      padding:0px 17px;
      position: relative;
      border-right: 1px solid #a1caef;

    }
    .nav-link {
      color: rgba(255, 255, 255, 0.55);
      font-size: 17px;
      line-height: 33px;
      &:hover{
        color: $white;
      }
      &.active{
        color: $white;
      }
    } 
}
}

.navbar{
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
}
.navbar-brand{
   max-width: 170px;
}
