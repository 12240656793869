@import "custom/custom-colorvariable";
@import "custom/custom-mainstyle";
@import "custom/custom-navbar";

@import "custom/custom-form";
@import "custom/custom-login";
@import "custom/custom-checkbox";
@import "custom/custom-userslist";
@import "custom/custom-table";
@import "custom/custom-pagination";
@import "custom/custom-botton";
@import "custom/custom-landing";
@import "custom/custom-navbar-landing";
@import "custom/custom-buyinfo";
@import "custom/custom-breadcrumb";
@import "custom/custom-elements";
@import "custom/custom-nav-tabs";
@import "custom/custom-order-review";
@import "custom/custom-layout";
@import "custom/custom-dashboard";
@import "custom/custom-currencyexchange";
@import "custom/page-header";
@import "custom/custom-card";
@import "custom/agent-profile";
@import "custom/loader";
@import "custom/media-queries";
@import "custom/admin-webapp";
@import "custom/custom-notifications";

/* My CSS */
.cursor-pointer {
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.cursor-progress {
  cursor: progress !important;
}
.form-control-text-area {
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 4px 8px #2c27380a;
  box-shadow: 0px 4px 8px #2c27380a;
  border: 1px solid #dbe2ea;
  opacity: 1;
  color: #000;
  font-size: 14px;
}

.form-control-text-area:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.card-header-white {
  padding: 35px 15px 0px 15px !important;
  background-color: #ffffff !important;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.icon-bell:before {
  content: url(../img/nav-menu/bell-icon.svg);
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.nav-link-active.icon-bell:before {
  content: url(../img/nav-menu/bell-icon-w.svg);
}
.my-hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.hover-white-text {
  opacity: 0.7;
  color: #ffffff !important;
  &:hover {
    opacity: 1;
  }
}
.btn-download-bg {
  background-color: #0880ae;
  color: #ffffff;
}
.marker-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  padding: 8px;
  max-width: 250px;
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 5px;
  }
  p {
    font-size: 11px;
    font-weight: 400;
    color: #756f86;
    margin-bottom: 5px;
  }
}
.pricelistBorder {
  z-index: 999999999999999;
  min-height: 90px;
  height: 100%;
  // background-color: #ccc;

  border: 1px solid #ccc;
}
.faircode-text {
  background: -webkit-linear-gradient(45deg, #2d9af7, #00ff95 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
html {
  scroll-behavior: smooth;
}
.footer-disclaimer {
  // padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;

  &.info-primary {
    color: $black;
    background-color: $clr-prymary-1;
  }
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.blue-underline {
  &:before {
    margin-top: 40px;
    content: url("../img/line-color-icon.svg");
  }
}
.no-head-notify {
  // head-notify class red dot removed
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.owl-carousel{
	.blk-carousel{
		position: relative;
		padding: 65px 0px;
		&:after{
			content: url("../img/carosl-top-1.png");
			position: absolute;
			top: 0px;
			left: 0px;
		}
/*		&:before{
			content: url("../img/carosl-btm.png");
			position: absolute;
			bottom: 0px;
			left: 0px;
		}	*/	
		h4{
		font-size: 25px;
		font-weight: 500;
		}
		p{
		font-size: 18px;
    font-weight: 400;
		}
	}
}

.left-check p::before{
  position: absolute;
  content: url("../img/white-check.png");
  left: -6px;
}

.landig-page .ing-bg-2 {
  background-image: url("../img/home-bg-2.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 320px) and (max-width: 500px) {
  .footer-disclaimers{
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*B2B*/
.banner-cnt.b2b {
  text-align: center;
}
.banner-cnt.b2b h1 {
  font-size: 33px;
  line-height: 39px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2px;
}
.banner-cnt.b2b p {
  margin: 0px;
  color: #fff;
  font-size: 18px;
  line-height: 43px;
  font-weight: 400;
}
.b2b-bn-img .lnd-blk-img .img-fluid{
  max-width: 95%;
}
.banner-cnt.b2b h2 {
  font-size: 18px;
  line-height: 43px;
  font-weight: 600;
  color: #fff;
  margin: 0px;
  background-color: #2cb9ce;
  padding: 0px 10px;
  display: inline-block;
}

.b2b-banner {
  background: #1EA1D0 url("../img/b2b/b2b-b1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 110px 0px 330px 0px;
}

.b2b-bn-img {
  position: relative;
}
.b2b-bn-img .lnd-blk-img {
  margin-top: -330px;
}

.b2b-card {
  text-align: center;
  margin: 20px 0px;
}
.b2b-card.b2b-card-bg {
  background-color: #f9fbff;
  padding: 25px 18px;
  box-shadow: 0px 5px 20px #4B4B4B14;
  border-radius: 12px;
}
.b2b-card.b2b-card-bg:hover {
  background-color: #FFFFFF;
}
.b2b-card.b2b-card-bg img {
  max-width: 50px;
}
.b2b-card h3 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  margin: 10px 32px;
  color: #2E2E2E;
}
.b2b-card p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.btn.btn-clr-yellow {
  background-color: #F6CF13 !important;
  color: #131D31 !important;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.b2bAccordian .accordion-button {
  background-color:#F1F4FA !important
}
.b2bAccordian .accordion-button h6{
  // font-weight: 600;
    font-size: 19px;
}
.b2b-landingpage{
  background-color: #f2f3fa !important;
  // font-family: "Montserrat", sans-serif !important;
}
.bg-gradiant {
  background: transparent linear-gradient(117deg, #7C0FC7 0%, #00D2D3 100%) 0% 0% no-repeat padding-box;
  padding: 80px 0px;
  position: relative;
}
.bg-gradiant .bg-gradiant-cnt h3 {
  font-size: 68px;
  line-height: 27px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 26px;
  padding-top: 17px;
}
.bg-gradiant .bg-gradiant-cnt p {
  font-size: 21px;
  line-height: 33px;
  font-weight: 400;
  color: #ffffff;
}
.left-check2 p {
  padding: 12px 0px 0px 24px !important;
  position: relative;
  font-size: 17px !important;
  line-height: 19px !important;
  color: #131D31;
  font-weight: 500;
  padding-left: 20px;
  height: 43px;
  letter-spacing: 0.69px;
}

.left-check2 p:before {
  position: absolute;
  content: url(../img/b2b/black-check.png);
  left: 0px;
}
.left-check {
  margin: 54px 0px 29px 0px;
}
.left-check p {
  padding: 12px 0px 0px 24px !important;
  position: relative;
  font-size: 17px !important;
  line-height: 19px !important;
  color: #FFFFFF;
  font-weight: 500;
  padding-left: 20px;
}
.left-check p:before {
  position: absolute;
  content: url("../img/b2b/white-check.png");
  left: 0px;
}

.lnd-accordion .card {
  background: none;
  border: none;
}
.lnd-accordion .card .card-body {
  padding: 10px;
}
.lnd-accordion .card .collapse.show {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.lnd-accordion .card .card-header {
  background: none;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.lnd-accordion .card .card-header h5 {
  font-weight: 600;
  font-size: 15px;
}
.lnd-accordion .card .card-header .btn-link {
  text-decoration: none;
}
.lnd-accordion .card .card-header .btn-link:before {
  content: "\f068";
  font-family: "FontAwesome";
  color: white;
}
.lnd-accordion .card .card-header .btn-link.collapsed:before {
  content: "\f067" !important;
  font-family: "FontAwesome";
  color: white;
}

.lnd-blk-tet h2 {
  font-size: 45px;
  line-height: 38px;
  font-weight: 600;
  color: #131D31;
  margin-bottom: 15px;
}
.lnd-blk-tet p {
  font-size: 18px;
  line-height: 38px;
  font-weight: 400;
  color: #131D31;
  margin-bottom: 1rem;
}

.lnd-blk-awrd h4 {
  font-size: 26px;
  font-weight: 600;
  color: #ffffff;
  margin: 30px 0px;
}
.lnd-blk-awrd h3 {
  font-size: 31px;
  line-height: 44px;
  font-weight: 500;
  color: #fbfbfb;
  margin: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3019607843);
  padding-bottom: 26px;
}

.landig-page.abouts .card {
  border: none;
}
.landig-page.abouts .ing-bg-3 {
  background-image: url(../img/b2b/about-star.png);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landig-page.abouts .our-team {
  text-align: center;
}
.landig-page.abouts .our-team h3 {
  font-size: 26px;
  font-weight: 600;
  color: #131D31;
  margin: 50px 0px;
}
.landig-page.abouts .our-team .avatar-team {
  margin: 0 auto;
  text-align: center;
}
.landig-page.abouts .our-team .avatar-team .img {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 auto;
  margin-bottom: 15px;
}
.landig-page.abouts .our-team .avatar-team .text p {
  font-size: 22px;
  line-height: 29px;
  font-weight: 600;
  color: #131D31;
}
.landig-page.abouts .our-team .avatar-team .text span {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #1F1F1F;
}
.landig-page.abouts .about-advisory {
  text-align: center;
}
.bg-gradiant .bg-icon {
  position: relative;
}
.bg-gradiant .bg-icon:before {
  position: absolute;
    left: 0;
    bottom: 125%;
    transform: translateX(1160px);
  content: url(../img/b2b/advisory2.png);
}
.bg-gradiant .bg-icon:after {
  position: absolute;
  right: 45px;
  top: 136%;
  content: url(../img/b2b/advisory1.png);
}
// .partnerFirst :before{
//   position: absolute;
//   right: 45px;
//   top: 136%;
//   content: url(../img/b2b/Image-21.jpg);
// }
// .partnerFirst :after{
//   position: absolute;
//   right: 45px;
//   top: 136%;
//   content: url(../img/b2b/Image-21.jpg);
// }
// .partnerFirst {
//   position: relative;
// }

@media (max-width: 1344px) {
  .bg-gradiant .bg-icon:before {
      // transform: translateX(493px);  
      display:none;
  }
  .bg-gradiant .bg-icon:after {
    // right: 5px;
    // top: 337%;
    display:none;
}
  
}
.landig-page.abouts .about-advisory .card {
  border-radius: 15px;
}
.landig-page.abouts .about-advisory h3 {
  font-size: 26px;
  font-weight: 600;
  color: #131D31;
  margin-bottom: 45px;
}
.landig-page.abouts .about-advisory .avatar-team {
  margin: 0 auto;
  text-align: center;
}
.landig-page.abouts .about-advisory .avatar-team .img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 auto;
  margin-bottom: 15px;
}
.landig-page.abouts .about-advisory .avatar-team .text {
  margin-bottom: 20px;
}
.landig-page.abouts .about-advisory .avatar-team .text p {
  font-size: 22px;
  line-height: 29px;
  font-weight: 600;
  color: #131D31;
}
.landig-page.abouts .about-advisory .avatar-team .text span {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #1F1F1F;
}
.landig-page.abouts .about-advisory .lnd-blk-tet p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #131D31;
  margin-bottom: 1rem;
}
.landig-page.abouts .about-advisory .lnd-blk-img {
  border-radius: 15px;
  overflow: hidden;
}
.landig-page.abouts .about-advisory .lnd-blk-img img {
  width: 100%;
}

.ml-auto {
  margin: 0 0 0 auto !important;
}

.benefit-bg {
  background-color: #6E25C8;
  padding: 69px;
  border-radius: 25px;
  color: white;
}

.mb-10 {
  margin: 15px 0px;
}

#benefits h2 {
  font: normal normal bold 38px/50px 'Montserrat';
  font-family: "Montserrat", sans-serif !important;
}
// #benefits{
//   left: 68px;
// }

.b2bBenefitAccordian .accordion-button {
  background-color:#8700cc00 !important;
  color:white;
}
.b2bBenefitAccordian .accordion-button h6{
  font-size: 20px;
  // font-weight: 600;
}
.benefit-bg .lnd-blk-img .img-fluid {
  max-width: 78%;
}
.b2b-text-center{
  text-align: center!important;
}
@media (max-width: 800px) {
  .b2b-text-center{
    text-align: left!important;
  }
}