    .buy-nav-tabs{
        .nav-tabs{
            border:none;
            .nav-item{
                .nav-link{
                    border:none;
                    background-color:none;
                    &.active{
                        border-bottom: 3px solid $clr-prymary-1;
                        background:none !important;
                    }
                    color: $light-text;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 500;
                }
            }
        }
    }