.BuyInfo{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
	&.BuySuccess{
		color: $green;
	}
}




/*-----------------card-------------------*/

.sender-mny-wrap{
    padding: 10px 0px;
    .card-header{
            padding: 35px 15px 0px 15px;

        background-color:$white;
    }
}


