.agent-profile-logo {
  p {
    color: #756f86;
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  img {
    max-width: 120px;
  }
}
.sb-sidenav-header {
  .c_profile {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;

    .c_profile-img {
      width: 200px;
      height: 200px;
      border-radius: 0px;
      overflow: hidden;
      img {
        width: 200px;
        height: 200px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .fileUpload {
      position: absolute;
      overflow: hidden;
      /* margin: 20px 0px; */
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background-color: #7c9cbf;
      color: #ffffff;
      justify-content: center;
      border-radius: 50px;
      bottom: 0px;
      right: 10px;

      input {
        &.upload {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          padding: 0;
          font-size: 20px;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
        }
      }
    }
  }
}

.Q_rcode {
  img {
    max-width: 201px;
  }
}
.Q_rcode-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
