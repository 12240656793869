.c-pagination {
  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .page-item {
    margin: 3px;
    &.active {
      .page-link {
        z-index: 1;
        color: #00d2d3;
        background-color: #00d2d31a;
      }
    }
    .page-link {
      color: #00d2d3;
      border: none;
      background: none;
      border-radius: 50px;
      font-size: 14px;
      &:focus {
        box-shadow: none;
      }
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: #00d2d3;
  }
}
