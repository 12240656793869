.card-header-white {
  padding: 35px 15px 0px 15px;
  background-color: #ffffff;
}
.card-h-white {
  background-color: #ffffff;
  border-bottom: 0px;
  padding-top: 26px;
  padding-bottom: 0px;
}

/*----------------Download-btn*--------------*/

.download_btn {
  background: #f5f6f7;
  padding: 15px 0px;
  &.Icon {
    .uploadLabel {
      padding-left: 45px;
      position: relative;
      background-color: #0880ae !important;
      &:before {
        position: absolute;
        left: 12px;
      }
    }
    &.Icon-download {
      .uploadLabel {
        &:before {
          content: url(../../img/nav-menu/CA_Users-h.svg);
        }
      }
    }
  }

  .uploadButton {
    display: none !important;
  }
  .uploadLabel {
    box-shadow: 0px 2px 4px #2c273814;
    display: inline-block;
    padding: 11px 20px;
    cursor: pointer;
    border-radius: 5px;
    background-color: $clr-prymary-1;
    color: #ffffff;
    margin: 10px 0px;
  }
}
