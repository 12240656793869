/*------------Login area------------------*/
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.buy-login {
  background-color: #ffffff;
  .login_header {
    position: absolute;
    left: 106px;
    top: 45px;
    img {
      max-width: 75%;
    }
  }
  .loginLeftImg {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 50%;
    }
  }
  .form-section {
    min-height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    background: #ffffff;
  }
  .form-inner {
    max-width: 500px;
    width: 100%;
    padding: 0 15px;
    .login-head {
      margin-bottom: 20px;
      h1 {
        text-align: center;
        font-size: 40px;
        color: $black;
        font-weight: 600;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #848484;
        font-weight: 500;
        opacity: 1;
      }
    }
    .form-group {
      .form-control {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #2c27380a;
        border: 1px solid #dbe2ea;
        opacity: 1;
        color: #000;
      }
      .form-label {
        text-align: left;
        letter-spacing: 0px;
        color: #756f86;
        opacity: 1;
        font-size: 13px;
        line-height: 28px;
        font-weight: 500;
        margin: 2px 0px !important;
      }
    }
    .forgot-password {
      color: $text-violet;
      text-decoration: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
    .haveacount {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      margin: 15px 0px;

      p {
        letter-spacing: 0px;
        color: #756f86;
        padding: 0px;
        margin: 0px;
        font-size: 16px;
      }
      a {
        color: $text-violet;
        text-decoration: none;
        font-size: 16px;
      }
    }
    .btn-lg {
      height: 60px;
      padding: 0.5rem 3rem;
    }
  }
  .bg-img {
    top: 0;
    bottom: 0;
    min-height: 100vh;
    text-align: left;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
    background: transparent linear-gradient(93deg, #705bed 0%, #00c6cc 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-check {
    .form-check-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $light-text;
    }
    .form-check-input {
      width: 22px;
      height: 22px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 8px #2c27380a;
      border: 1px solid #dbe2ea;
      opacity: 1;
    }
  }
  .checkbox {
    .form-check {
      padding-left: 37px;
      .form-check-input {
        margin-left: -2.25rem;
      }
    }
  }
}

/*------------Login area-------End-----------*/

/*------------otp area-------start-----------*/
.otp-wrap {
  .form-group {
    input {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

/*------------otp area-------End-----------*/

.order-received {
  h4 {
    font-size: 21px;
    font-weight: 400;
    line-height: 35px;
    margin: 15px 0px;
    .text-bold {
      font-weight: 500;
    }
  }
  p {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: $black !important;
    margin: 15px 0px;
  }
}
.m0-auto {
  margin: 0 auto;
}
