.btn {
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.btn-primary {
    background-color: $btn-primary-g !important;
    color: $white !important;
    border: $btn-primary-g;
  }
  &.btn-danger {
    background-color: #b2162e !important;
    color: $white !important;
    border: #b2162e;
  }

  &.btn-primary-g {
    background-color: $btn-primary-g;
    color: $white;
  }
  &.btn-primary-b {
    background-color: $btn-primary-b;
    color: $white;
  }
  &.btn-primary-v {
    background-color: $btn-primary-v;
    color: $white;
  }
  &.btn-primary-b-g {
    border: 1px solid $btn-primary-g;
    color: $btn-primary-g;
    background-color: $white;
  }
  &.btn-primary-b-g-v {
    border: 1px solid $btn-primary-v;
    color: $btn-primary-v;
    background-color: $white;
  }

  &.btn-lg {
    padding: 8px 30px;
    line-height: 40px;
  }
}
.btn-md {
  padding: 8px 30px;
  line-height: 28px;
}
.btn-right {
  float: right;
}

.info {
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0px;

  &.info-primary {
    color: $black;
    background-color: $clr-prymary-1;
  }
}
