/*-------breadcrumb--------*/
.c-breadcrumb {
  .fa {
    color: $light-text;
  }
  .breadcrumb {
    background: none !important;
    padding: 10px 0px;
    margin-bottom: 0px;
    .breadcrumb-item {
      a {
        color: $light-text;
        font-weight: 400;
        font-size: 15px;
      }
      &.active {
        a {
          color: $clr-prymary-1 !important;
        }
      }
      &:before {
        content: "\f0da";
        font-family: "FontAwesome";
        padding: 0px 15px;
        color: $light-text;
      }
    }
  }
}
